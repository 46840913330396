<template>
  <div id="jepx_spot" class="jepxSpot">
    <a href="#" class="jepxSpot-dataType" @click.prevent="onClickDataType">
      <transition v-for="item in dataList" :key="item.name" name="flipUp">
        <img
          v-show="currentDataName === item.name"
          :src="item.path"
          :alt="item.text"
          class="jepxSpot-headingImage"
          :class="item.class"
        />
      </transition>
    </a>
    <div v-for="areaKey in areaKeys" :key="areaKey" class="jepxSpot-tabContainer">
      <JepxSpotTab
        :data-names="dataNames"
        :current-data-name="currentDataName"
        :current-area-key="currentAreaKey"
        :day-prices="getDayPrices(theItems, areaKey)"
        :prev-day-prices="getDayPrices(prevItems, areaKey)"
        @change-tab="onChangeTab"
      />
    </div>
  </div>
</template>

<script>
import JepxSpotTab from './JepxSpotTab.vue'
import getImagePath from './functions/getImagePath.js'

import 'common/text-da-24.svg'
import 'common/text-da-dt.svg'
import 'common/text-da-pt.svg'

export default {
  name: 'JepxSpot',
  components: {
    JepxSpotTab,
  },
  /**
   * @typedef {Object.<string, number, Array, Object>} Props
   * @property {string} currentAreaKey - 表示されているエリアキー
   * @property {Object} allItems - JEPX情報のリスト
   * @property {number} index - JEPX情報Arrayのうち表示するindex
   * @property {string[]} areaKeys - エリアキーのリスト
   * @property {Object} theItems - 表示されている日のJEPX情報
   * @property {Object} prevItems - 表示日の前日のJEPX情報
   */
  props: {
    currentAreaKey: {
      type: String,
      required: true,
    },
    allItems: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    areaKeys: {
      type: Array,
      required: true,
    },
    theItems: {
      type: Object,
      required: true,
    },
    prevItems: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  /**
   * @typedef {Object.<number, any>} Data
   * @property {number} currentDataIndex - dataListのうち表示するindex
   * @property {*} currentDataTimer - currentDataIndexを切り替えるtimer関数
   */
  data() {
    return {
      currentDataIndex: 0,
      currentDataTimer: null,
    }
  },
  computed: {
    /**
     * 切り替えるデータの表示用情報
     * @returns {({path: *, name: string, text: string, class: string}|{path: *, name: string, text: string, class: string}|{path: *, name: string, text: string, class: string})[]}
     */
    dataList() {
      return [
        {
          name: 'average',
          text: '24h平均',
          path: this.getImagePath('common/text-da-24.svg'),
          class: 't-da-24',
        },
        {
          name: 'daytime_average',
          text: '08-22DayTime平均',
          path: this.getImagePath('common/text-da-dt.svg'),
          class: 't-da-dt',
        },
        {
          name: 'peaktime_average',
          text: '13-16PeakTime平均',
          path: this.getImagePath('common/text-da-pt.svg'),
          class: 't-da-pt',
        },
      ]
    },
    /**
     * dataListからnameのみ抽出した配列
     * @returns {string[]}
     */
    dataNames() {
      return this.dataList.map((item) => item.name)
    },
    /**
     * 現在表示されているdataListのnameプロパティ
     * @returns {string}
     */
    currentDataName() {
      return this.dataList[this.currentDataIndex].name
    },
  },
  mounted() {
    this.startTimer()
  },
  methods: {
    getImagePath,
    /**
     * 再帰的に currentDataIndex を切り替える
     * @param {number} [timeout] - 初回タイムアウト（ミリ秒）
     * @param {number} [nextTimeout] - 再帰的に呼び出される場合のタイムアウト（ミリ秒）
     */
    startTimer(timeout = 6000, nextTimeout = 6000) {
      this.currentDataTimer = setTimeout(() => {
        this.toggleCurrentDataIndex()
        this.startTimer(nextTimeout)
      }, timeout)
    },
    /**
     * 表示データの種別アイコンがクリックされたときの処理
     * currentDataIndexを即時に切り替え、次のタイマーを比較的長い初回タイムアウト値で開始
     * （能動的に切り替えられたのにすぐまた切り替わってしまったらユーザーが嫌がりそうなので）
     */
    onClickDataType() {
      if (this.currentDataTimer) clearTimeout(this.currentDataTimer)
      this.toggleCurrentDataIndex()
      this.startTimer(12000)
    },
    /**
     * currentDataIndexを次のものに切り替える 末尾の場合は先頭に戻る
     */
    toggleCurrentDataIndex() {
      this.currentDataIndex = (this.currentDataIndex + 1) % this.dataList.length
    },
    /**
     * JEPX価格情報から対象のエリアキーの値のみを、 areaKey を付加して返却
     * @param {Object} target - 対象のpriceオブジェクト
     * @param {string} areaKey - エリアキー
     * @returns {Object|undefined}
     */
    getDayPrices(target, areaKey) {
      return target ? Object.assign({ areaKey }, target[areaKey]) : undefined
    },
    /**
     * タブがクリックされたときの処理 変更するタブ名を親に通知する
     * @param {string} areaKey - エリアキー
     */
    onChangeTab(areaKey) {
      this.$emit('change-tab', areaKey)
    },
  },
}
</script>

<style lang="scss" scoped>
@use 'css/foundation/_vars.scss';
@import 'css/vue_transition/_flip_up.scss';

.jepxSpot {
  display: flex;
  flex-wrap: nowrap;
}

.jepxSpot-dataType {
  position: relative;
  display: block;
  flex: 0 0 auto;
  align-self: center;
  width: 5.8rem;
  height: 5.8rem;
  margin-right: 10px;
  background: vars.$color-btn-accept-in-light;
  border: 2px solid #1c1c1c;
  border-radius: 50%;
}

.jepxSpot-headingImage {
  position: absolute;
  right: 0;
  left: 0;
  display: block;
  margin: auto;

  &.t-da-24 {
    top: 1.5rem;
    width: 20px;
    height: auto;
  }

  &.t-da-dt,
  &.t-da-pt {
    top: 1.2rem;
    width: 36px;
    height: auto;
  }
}

.jepxSpot-tabContainer {
  flex: 0 0 auto;
}
</style>
