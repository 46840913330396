<template>
  <tr class="jepxTable-row t-jepx">
    <td class="jepxTable-cell t-padding t-sticky" :style="stickyPositions[0]"></td>
    <td class="jepxTable-cell t-heading t-sticky" :style="stickyPositions[1]">
      <span class="jepxTable-cellInner t-heading">{{ thisAreaName }}</span>
    </td>
    <td
      class="jepxTable-cell t-sticky"
      :class="getClassNameByColor(max)"
      :style="stickyPositions[2]"
      @mouseover="onMouseOver(['max'])"
      @mouseout="onMouseOut()"
    >
      <span class="jepxTable-cellInner" :class="{ 'is-active': checkIsActive(['max']) }">{{
        max | fixed
      }}</span>
    </td>
    <td
      class="jepxTable-cell t-sticky"
      :class="getClassNameByColor(min)"
      :style="stickyPositions[3]"
      @mouseover="onMouseOver(['min'])"
      @mouseout="onMouseOut()"
    >
      <span class="jepxTable-cellInner" :class="{ 'is-active': checkIsActive(['min']) }">{{
        min | fixed
      }}</span>
    </td>
    <td
      class="jepxTable-cell t-sticky t-sectionEnd"
      :class="getClassNameByColor(average)"
      :style="stickyPositions[4]"
      @mouseover="onMouseOver(['average'])"
      @mouseout="onMouseOut()"
    >
      <span class="jepxTable-cellInner" :class="{ 'is-active': checkIsActive(['average']) }">{{
        average | fixed
      }}</span>
    </td>

    <td
      v-for="(price, index) in prices"
      :key="index"
      class="jepxTable-cell"
      :class="getClassNameByColor(price)"
      @mouseover="onMouseOver([index])"
      @mouseout="onMouseOut()"
    >
      <span class="jepxTable-cellInner" :class="{ 'is-active': checkIsActive([index]) }">{{
        price | fixed
      }}</span>
      <template v-if="!isSingle && segmentTexts[index]">
        <img
          src="~common/icon-segment.svg"
          :alt="`分断 ${segmentTexts[index]}`"
          class="jepxTable-segment"
        />
        <span class="jepxTable-segmentDetail"
          ><span class="jepxTable-segmentTitle">分断:</span> {{ segmentTexts[index] }}</span
        >
      </template>
    </td>

    <td class="jepxTable-cell t-padding"></td>
  </tr>
</template>

<script>
import SegmentMixin from './mixins/Segment'
import JepxTableRowMixin from './mixins/JepxTableRow'

import colorThresholds from './data/colorThresholds'

import 'common/icon-segment.svg'

export default {
  name: 'JepxDayArea',
  filters: {
    fixed(num) {
      return num.toFixed(2)
    },
  },
  mixins: [JepxTableRowMixin, SegmentMixin],
  /**
   * @typedef {Object.<string, Array, Object>} Props
   * @property {string} areaKey - エリアキー
   * @property {Object.<number, string, Array>} dayPrices - 日付の出力用情報 JEPX価格など
   * @property {Object[]} [stickyPositions] - スクロールしないカラム群の右端のx座標（CSSとして解釈できる文字列）
   * @property {boolean} [isSingle] - エリア単独のタブであるか
   */
  props: {
    areaKey: {
      type: String,
      required: true,
    },
    dayPrices: {
      type: Object,
      required: true,
    },
    stickyPositions: {
      type: Array,
      required: false,
      default: () => [],
    },
    isSingle: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    /**
     * エリア単独のタブであれば'スポット'を、全国タブであればエリア名を出力
     * @returns {string}
     */
    thisAreaName() {
      return this.isSingle ? 'スポット' : this.dayPrices.name
    },
    /**
     * Max列の出力
     * @returns {number}
     */
    max() {
      return this.dayPrices.max
    },
    /**
     * Min列の出力
     * @returns {number}
     */
    min() {
      return this.dayPrices.min
    },
    /**
     * Avg.列, 24h平均の出力
     * @returns {number}
     */
    average() {
      return this.dayPrices.average
    },
    /**
     * Daytime平均の出力
     * @returns {number}
     */
    daytime_average() {
      return this.dayPrices.daytime_average
    },
    /**
     * PeakTime平均の出力
     * @returns {number}
     */
    peaktime_average() {
      return this.dayPrices.peaktime_average
    },
    /**
     * JEPX価格（00:00〜23:30の24コマ）
     * @returns {number[]}
     */
    prices() {
      return this.dayPrices.prices
    },
    /**
     * 西側の分断情報が存在するか（00:00〜23:30の24コマ）
     * 全国表示では西側の分断情報を表示しないため全て強制的に falsy な値で埋める
     * @returns {any[]}
     */
    hasWestSegments() {
      return new Array(48)
    },
    /**
     * 境界値と色情報の対応
     * @returns {{min: number, level: number}[]}
     */
    colorThresholds() {
      return colorThresholds()
    },
  },
  methods: {
    /**
     * JEPX価格を色情報classに変換
     * @param {number} price
     * @returns {string}
     */
    getClassNameByColor(price) {
      const threshold = this.colorThresholds.find((obj) => {
        return (obj.min ?? -Infinity) <= price
      })
      return `t-l${threshold?.level ?? '-error'}`
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'css/vue_modules/_jepx_table.scss';
</style>
