<template>
  <a href="#" class="jepxSpotTab" :class="{ 'is-active': isActive }" @click.prevent="onClick">
    <p
      class="jepxSpotTab-name"
      :class="{ 'is-increased': isIncreased, 'is-decreased': isDecreased }"
      v-text="areaName"
    ></p>

    <transition v-for="name in dataNames" :key="name" name="flipUp">
      <p
        v-show="currentDataName === name"
        class="jepxSpotTab-num"
        v-text="numToFixedPointStr(dayPrices[name])"
      ></p>
    </transition>
  </a>
</template>

<script>
import numToFixedPointStr from './functions/numToFixedPointStr'

export default {
  name: 'JepxSpotTab',
  /**
   * @typedef {Object.<string, Array, Object>} Props
   * @property {string[]} dataNames - dataListからnameのみ抽出した配列
   * @property {string} currentDataName - 表示されているdataName
   * @property {Object} dayPrices - 表示されている日のJEPX価格情報
   * @property {string} currentAreaKey - 表示されているエリアキー
   * @property {Object} [prevDayPrices] - 表示日の前日のJEPX価格情報
   */
  props: {
    dataNames: {
      type: Array,
      required: true,
    },
    currentDataName: {
      type: String,
      required: true,
    },
    dayPrices: {
      type: Object,
      required: true,
    },
    currentAreaKey: {
      type: String,
      required: true,
    },
    prevDayPrices: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {}
  },
  computed: {
    /**
     * 表示するエリア名 システムの場合は全国に差し替える
     * @returns {string}
     */
    areaName() {
      return this.dayPrices.name === 'システム' ? '全国' : this.dayPrices.name
    },
    /**
     * 表示されている日の値を currentDataName によって動的に返却
     * @returns {number}
     */
    currentPriceOfTheDay() {
      return this.dayPrices[this.currentDataName]
    },
    /**
     * 表示されている前日の値を currentDataName によって動的に返却
     * @returns {number}
     */
    currentPriceOfPrevDay() {
      return this.prevDayPrices ? this.prevDayPrices[this.currentDataName] : undefined
    },
    /**
     * 前日より価格が上昇している（上矢印を表示する）か
     * @returns {boolean}
     */
    isIncreased() {
      return this.currentPriceOfPrevDay && this.currentPriceOfTheDay > this.currentPriceOfPrevDay
    },
    /**
     * 前日より価格が下落している（下矢印を表示する）か
     * @returns {boolean}
     */
    isDecreased() {
      return this.currentPriceOfPrevDay && this.currentPriceOfTheDay < this.currentPriceOfPrevDay
    },
    /**
     * このタブが選択されているか
     * @returns {boolean}
     */
    isActive() {
      return this.currentAreaKey === this.dayPrices.areaKey
    },
  },
  methods: {
    numToFixedPointStr,
    /**
     * タブがクリックされたときの処理 親にこのタブのエリアキーを通知
     */
    onClick() {
      if (!this.isActive) this.$emit('change-tab', this.dayPrices.areaKey)
    },
  },
}
</script>

<style lang="scss" scoped>
@use 'css/foundation/_vars.scss';
@use 'css/foundation/_mixins.scss';
@import 'css/vue_transition/_flip_up.scss';

.jepxSpotTab {
  position: relative;
  display: block;
  min-width: 8rem;
  height: calc(100% + 7px);
  padding: 5px 6px 16px;
  color: vars.$color-text;
  text-align: center;
  text-decoration: none;
  border-top: 2px solid transparent;
  border-right: 2px solid transparent;
  border-left: 2px solid transparent;
  border-radius: 7px 7px 0 0;
  transition: min-width 0.5s vars.$ease-out-quart, background 0.5s vars.$ease-out-quart,
    border 0.5s vars.$ease-out-quart;

  @include mixins.mq('narrowPcForJepxTab') {
    min-width: 7rem;
    padding-right: 0;
    padding-left: 0;
  }

  &.is-active {
    min-width: 9.3rem;
    background: vars.$color-btn-accept-in-light;
    background: vars.$color-bg-1;
    border-color: vars.$color-assist-1;

    @include mixins.mq('narrowPcForJepxTab') {
      min-width: 8.3rem;
    }

    &::after {
      position: absolute;
      right: 0;
      bottom: 4px;
      left: 0;
      display: block;
      width: calc(100% - 10px);
      height: 2px;
      margin: auto auto 0;
      content: '';
      background: rgba(#fff, 0.27);
    }
  }
}

.jepxSpotTab-name {
  position: relative;
  display: inline-block;
  padding-left: 4px;
  font-size: 1.5rem;

  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -4px;
    display: block;
    width: 6px;
    height: 0;
    margin: auto auto auto 0;
    visibility: hidden;
    content: '';
    border-style: solid;
  }

  &.is-increased,
  &.is-decreased {
    &::before {
      width: 0;
      visibility: visible;
    }
  }

  &.is-increased::before {
    border-color: transparent transparent vars.$color-icon-increased transparent;
    border-width: 0 3px 6px 3px;
  }

  &.is-decreased::before {
    border-color: vars.$color-icon-decreased transparent transparent transparent;
    border-width: 6px 3px 0 3px;
  }
}

.jepxSpotTab-num {
  position: absolute;
  top: 2.8rem;
  right: 0;
  left: 0;
  margin: 0 auto;
  font-family: vars.$font-family-narrow;
  font-size: 1.8rem;
  font-weight: 600;
  text-align: center;
}
</style>
