<template>
  <tr class="jepxTable-row t-jepx">
    <td class="jepxTable-cell t-separator t-padding t-sticky" :style="stickyPositions[0]"></td>
    <th class="jepxTable-cell t-separator t-sticky" :style="stickyPositions[1]">
      <span class="separator t-right t-hasPadding"></span>
    </th>
    <th
      class="jepxTable-cell t-separator t-sticky"
      :style="stickyPositions[2]"
      @mouseover="onMouseOver(['max'])"
      @mouseout="onMouseOut()"
    >
      <span class="separator"></span>
    </th>
    <th
      class="jepxTable-cell t-separator t-sticky"
      :style="stickyPositions[3]"
      @mouseover="onMouseOver(['min'])"
      @mouseout="onMouseOut()"
    >
      <span class="separator"></span>
    </th>
    <th
      class="jepxTable-cell t-separator t-sectionEnd"
      :style="stickyPositions[4]"
      @mouseover="onMouseOver(['average'])"
      @mouseout="onMouseOut()"
    >
      <span class="separator"></span>
    </th>
    <td
      v-for="(time, index) in timeCodes"
      :key="time"
      class="jepxTable-cell t-separator"
      @mouseover="onMouseOver([index])"
      @mouseout="onMouseOut()"
    >
      <span class="separator" :class="{ 't-hasPadding': index === timeCodes.length - 1 }"></span>
    </td>
    <td class="jepxTable-cell t-separator t-padding"></td>
  </tr>
</template>

<script>
import JepxTableRowMixin from './mixins/JepxTableRow'

export default {
  name: 'JepxSeparatorRow',
  mixins: [JepxTableRowMixin],
  /**
   * @typedef {Object.<string, Array, Object>} Props
   * @property {string[]} timeCodes - タイムコードのリスト
   * @property {Object[]} [stickyPositions] - スクロールしないカラム群の右端のx座標（CSSとして解釈できる文字列）
   */
  props: {
    timeCodes: {
      type: Array,
      required: true,
    },
    stickyPositions: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'css/vue_modules/_jepx_table.scss';

.separator {
  position: relative;
  display: block;
  width: 100%;
  height: 1px;

  &::before {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    margin: auto;
    content: '';
    background: #575757;
  }

  &.t-right {
    &::before {
      right: 0;
      left: auto;
    }
  }

  &.t-hasPadding {
    &::before {
      width: calc(100% - 4px);
    }
  }
}
</style>
