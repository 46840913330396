/**
 * 天気キーとタイムコードをアイコン表示用の情報に変換
 * 晴れ・曇りは昼夜でアイコンが異なるためタイムコードを使用
 * @param {string} weatherName - 天気情報キー
 * @param {number} timeCode - タイムコード（00:00〜23:30の48コマを0〜47の数字で表現）
 * @param {Object} weatherIconPaths - 天気アイコンのパス
 * @returns {Object}
 */
export default function getWeatherIcon({ weatherName, timeCode, weatherIconPaths }) {
  switch (weatherName) {
    case 'clear':
    case 'sunny':
    case 'cloudy':
      return weatherIconPaths[`${weatherName}_${getTimePeriod(timeCode)}`]
    default:
      return weatherIconPaths[weatherName]
  }
}

/**
 * タイムコードを昼夜を示すテキストに変換
 * 05:00〜18:30 (timeCode 9 - 36) を日中とする
 * @param timeCode - タイムコード（00:00〜23:30の48コマを0〜47の数字で表現）
 * @returns {string}
 */
function getTimePeriod(timeCode) {
  return timeCode >= 10 && timeCode <= 36 ? 'daytime' : 'nighttime'
}
