/**
 * 受け取ったパスをimgエレメントに入れてPromiseで返却
 * @param {string} src - ソースのパス
 * @returns {Promise<unknown>}
 */
export default function loadImage(src) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve(img)
    img.onerror = (e) => reject(e)
    img.src = src
  })
}
