<template>
  <tr class="jepxTable-row">
    <td class="jepxTable-cell t-padding t-sticky" :style="stickyPositions[0]"></td>
    <td class="jepxTable-cell t-heading t-sticky t-sectionEnd" :style="stickyPositions[1]">
      <span class="jepxTable-cellInner t-heading">分断情報</span>
    </td>
    <td
      class="jepxTable-cell t-spanned t-sticky t-smallerText t-sectionEnd"
      colspan="3"
      :style="stickyPositions[2]"
      @mouseover="onMouseOver(['max', 'min', 'average'])"
      @mouseout="onMouseOut()"
    >
      <span
        class="jepxTable-cellInner"
        :class="{ 'is-active': checkIsActive(['max', 'min', 'average']) }"
        >{{ countOfSegment }}コマ</span
      >
    </td>

    <td
      v-for="(segmentText, index) in segmentTexts"
      :key="index"
      class="jepxTable-cell t-noBG"
      @mouseover="onMouseOver([index])"
      @mouseout="onMouseOut()"
    >
      <span
        class="jepxTable-cellInner t-hasSegmentDetail"
        :class="{ 't-hasSegmentDetail': segmentText, 'is-active': checkIsActive([index]) }"
      >
        <template v-if="segmentText">
          <img
            src="~common/icon-segment.svg"
            :alt="`分断 ${segmentText}`"
            class="jepxTable-segment t-single"
          />
          <span class="jepxTable-segmentDetail t-single">
            <!--
              TODO: βリリース時はスキップ リンク先が正しいか未確認
              <span class="jepxTable-segmentParagraph t-largeText">
                <span class="jepxTable-segmentTitle">HJKS:</span>
                <a href="https://hjks.jepx.or.jp/hjks/top" target="_blank" rel="noopener"
                  >7件 合計2,777,200 (kW)</a
                >
              </span>
            -->
            <span class="jepxTable-segmentParagraph">
              <span class="jepxTable-segmentTitle">分断:</span> {{ segmentText }}</span
            >
          </span>
        </template>

        <span v-else class="jepxTable-noSegment t-single"></span>
      </span>
    </td>

    <td class="jepxTable-cell t-padding"></td>
  </tr>
</template>

<script>
import JepxTableRowMixin from './mixins/JepxTableRow'
import SegmentMixin from './mixins/Segment.js'
import 'common/icon-segment.svg'

export default {
  name: 'JepxSegmentRow',
  mixins: [JepxTableRowMixin, SegmentMixin],
  /**
   * @typedef {Object.<string, Array, Object>} Props
   * @property {string} areaKey - エリアキー
   * @property {Object.<number, string, Array>} dayPrices - 日付の出力用情報 JEPX価格など
   * @property {Object[]} [stickyPositions] - スクロールしないカラム群の右端のx座標（CSSとして解釈できる文字列）
   */
  props: {
    areaKey: {
      type: String,
      required: true,
    },
    dayPrices: {
      type: Object,
      required: true,
    },
    stickyPositions: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    /**
     * エリア単独のタブであればJEPXを、全国タブであればエリア名を出力
     * @returns {string}
     */
    thisAreaName() {
      return this.dayPrices.name
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'css/vue_modules/_jepx_table.scss';
</style>
