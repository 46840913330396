import areas from '../data/areas'

export default {
  computed: {
    thisAreaIndex() {
      return areas().findIndex((area) => area.id === this.areaKey)
    },
    hasEastSegments() {
      return this.dayPrices.has_east_segments
    },
    hasWestSegments() {
      return this.dayPrices.has_west_segments
    },
    eastAreaName() {
      return this.getNeighborAreaName(-1)
    },
    westAreaName() {
      return this.getNeighborAreaName(1)
    },
    segmentTexts() {
      return this.dayPrices.prices.map((_item, index) => this.getSegmentTextByIndex(index))
    },
    countOfSegment() {
      return this.segmentTexts.filter((item) => item).length
    },
  },
  methods: {
    getNeighborAreaName(offset) {
      const area = this.thisAreaIndex >= 0 ? areas()[this.thisAreaIndex + offset] || {} : {}
      return area.name
    },
    getSegmentTextByIndex(index) {
      return [
        this.hasEastSegments[index]
          ? this.createSegmentText(this.thisAreaName, this.eastAreaName)
          : undefined,
        this.hasWestSegments[index]
          ? this.createSegmentText(this.thisAreaName, this.westAreaName)
          : undefined,
      ]
        .filter(Boolean)
        .join(', ')
    },
    createSegmentText(origin, destination) {
      return `${origin}⇔${destination}`
    },
  },
}
