import weatherIconPaths from '../data/weatherIconPaths.js'
import getImagePath from '../functions/getImagePath.js'

export default {
  computed: {
    weatherIconPaths,
  },
  methods: {
    getImagePath,
  },
}
