/**
 * 境界値と色情報の対応
 * @returns {{min: number, level: number}[]}
 */
export default function colorThresholds() {
  return [
    { level: 9, min: 20 },
    { level: 8, min: 17.5 },
    { level: 7, min: 15 },
    { level: 6, min: 12.5 },
    { level: 5, min: 10 },
    { level: 4, min: 7.5 },
    { level: 3, min: 5 },
    { level: 2, min: 2.5 },
    { level: 1, min: 0 },
    { level: 0 },
  ]
}
