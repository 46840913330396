<template>
  <div class="JepxLegend">
    <a class="commonButton t-chartaccent detailButton" href="/markets/price_checker" target="_blank"
      >詳細分析</a
    >
    <p class="JepxLegend-title">凡例</p>
    <ul class="JepxLegend-list">
      <li
        v-for="threshold in colorThresholdsForDisplay"
        :key="threshold.level"
        class="JepxLegend-list-item"
      >
        <p class="JepxLegend-list-text">{{ threshold.minText }}</p>
        <div class="JepxLegend-list-color" :class="threshold.class"></div>
      </li>
    </ul>
  </div>
</template>

<script>
import colorThresholds from '../../../data/colorThresholds'

export default {
  name: 'JepxLegend',
  computed: {
    colorThresholds() {
      return colorThresholds().sort(function (prev, next) {
        return Math.sign(prev.level - next.level)
      })
    },
    colorThresholdsForDisplay() {
      return this.colorThresholds.map((threshold) => ({
        ...threshold,
        minText: threshold.min?.toFixed(2) ?? '',
        class: `t-l${threshold.level}`,
      }))
    },
  },
}
</script>

<style lang="scss" scoped>
@use '../../../css/foundation/_vars.scss';

.JepxLegend {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.JepxLegend-title {
  flex: 0 0 auto;
  align-self: center;
  min-width: 5.4rem;
  font-size: 1rem;
  text-align: right;
}

.JepxLegend-list {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin-left: 0.4rem;
}

.JepxLegend-list-item {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
  min-width: 4rem;
  font-family: vars.$font-family-narrow;
  font-size: 1rem;

  &:last-child {
    min-width: 8rem;
  }

  & + & {
    margin-left: 5px;
  }
}

.JepxLegend-list-text {
  width: 4em;
  text-align: center;
  transform: translateX(-2.2em);
}

.JepxLegend-list-color {
  height: 0.4rem;

  @each $key, $val in vars.$colors-chart {
    &.t-#{$key} {
      background: $val;
    }
  }
}
.detailButton {
  margin-left: 2rem;
}
</style>
