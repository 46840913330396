<template>
  <section id="jepx">
    <div class="header">
      <div class="header-logoArea">
        <h1 class="imkLogo">
          <a href="/markets" class="imkLogo-link">
            <img
              src="~common/logo-imk.svg"
              alt="ENECHANGE INSIGHTS MARKETS"
              class="imkLogo-image"
            />
          </a>
        </h1>
      </div>

      <div class="header-selectorArea">
        <ItemsByDay
          :the-day="theDay"
          :prev-day="prevDay"
          :next-day="nextDay"
          :is-simple="false"
          @prev="prev"
          @next="next"
        />
      </div>

      <div class="header-dataArea">
        <JepxSpot
          :all-items="allItems"
          :index="index"
          :area-keys="areaKeys"
          :current-area-key="currentAreaKey"
          :the-items="theItems"
          :prev-items="prevItems"
          @change-tab="onChangeTab"
        />
      </div>
    </div>

    <div class="jepxSection">
      <JepxLegend />

      <div class="jepxSection-tableContainer">
        <table class="jepxTable">
          <JepxTimeRow
            :day-prices="currentDayPrices"
            :time-codes="timeCodes"
            :sticky-positions="stickyPositionStyles"
            :active-cell-indexes="activeCellIndexes"
            @change-active-cell-indexes="changeActiveCellIndexes"
          />

          <template v-if="currentAreaKey === 'system'">
            <JepxDayArea
              v-for="areaKey in areaKeys"
              :key="areaKey"
              :area-key="areaKey"
              :day-prices="day_prices_of(areaKey)"
              :sticky-positions="stickyPositionStyles"
              :active-cell-indexes="activeCellIndexes"
              @change-active-cell-indexes="changeActiveCellIndexes"
            />
          </template>

          <template v-else>
            <JepxDayArea
              :area-key="currentAreaKey"
              :day-prices="currentDayPrices"
              :sticky-positions="stickyPositionStyles"
              :is-single="true"
              :active-cell-indexes="activeCellIndexes"
              @change-active-cell-indexes="changeActiveCellIndexes"
            />

            <JepxTemperatureRow
              :weathers="formattedWeatherForecastsOfCurrentArea"
              :sticky-positions="stickyPositionStyles"
              :is-forecast="true"
              :active-cell-indexes="activeCellIndexes"
              @change-active-cell-indexes="changeActiveCellIndexes"
            />

            <JepxWeatherRow
              :weathers="formattedWeatherForecastsOfCurrentArea"
              :sticky-positions="stickyPositionStyles"
              :is-forecast="true"
              :active-cell-indexes="activeCellIndexes"
              @change-active-cell-indexes="changeActiveCellIndexes"
            />

            <JepxSeparatorRow
              :sticky-positions="stickyPositionStyles"
              :time-codes="timeCodes"
              :active-cell-indexes="activeCellIndexes"
              @change-active-cell-indexes="changeActiveCellIndexes"
            />

            <JepxTemperatureRow
              :weathers="formattedWeatherActualsOfCurrentArea"
              :sticky-positions="stickyPositionStyles"
              :is-forecast="false"
              :active-cell-indexes="activeCellIndexes"
              @change-active-cell-indexes="changeActiveCellIndexes"
            />

            <JepxWeatherRow
              :weathers="formattedWeatherActualsOfCurrentArea"
              :sticky-positions="stickyPositionStyles"
              :is-forecast="false"
              :active-cell-indexes="activeCellIndexes"
              @change-active-cell-indexes="changeActiveCellIndexes"
            />

            <JepxSunlightRow
              :weathers="formattedWeatherActualsOfCurrentArea"
              :sticky-positions="stickyPositionStyles"
              :weather-key="weatherKey"
              :current-area-key="currentAreaKey"
              :active-cell-indexes="activeCellIndexes"
              @change-active-cell-indexes="changeActiveCellIndexes"
            />

            <JepxSeparatorRow
              :sticky-positions="stickyPositionStyles"
              :time-codes="timeCodes"
              :active-cell-indexes="activeCellIndexes"
              @change-active-cell-indexes="changeActiveCellIndexes"
            />

            <!--
              TODO: βリリース時はスキップ
              <JepxHjksRow
                :day-prices="currentDayPrices"
                :sticky-positions="stickyPositionStyles"
              />
            -->

            <JepxSegmentRow
              :area-key="currentAreaKey"
              :day-prices="currentDayPrices"
              :sticky-positions="stickyPositionStyles"
              :active-cell-indexes="activeCellIndexes"
              @change-active-cell-indexes="changeActiveCellIndexes"
            />
          </template>

          <tr class="jepxTable-row t-jepx">
            <td class="jepxTable-cell t-padding"></td>
            <td colspan="53">
              <div class="jepxTable-chartWrapper">
                <div class="jepxTable-highlight" :style="highlightStyle"></div>
                <div class="jepxTable-chartWrapper-inner">
                  <JepxTimeChart
                    :the-items="theItems"
                    :weather-forecasts="formattedWeatherForecastsOfCurrentArea"
                    :weather-actuals="formattedWeatherActualsOfCurrentArea"
                    :current-area-key="currentAreaKey"
                    :time-codes="timeCodes"
                    :active-cell-indexes="activeCellIndexes"
                    @change-active-cell-indexes="changeActiveCellIndexes"
                  />
                </div>
              </div>
            </td>
            <td class="jepxTable-cell t-padding"></td>
          </tr>
        </table>
      </div>

      <p class="jepxSection-help">
        JEPX引用元:
        <cite
          ><a href="http://www.jepx.org/market/index.html" target="_blank" rel="noopener"
            >一般社団法人日本卸電力取引所</a
          ></cite
        >
      </p>
    </div>
  </section>
</template>

<script>
import size from 'lodash/size'

import JepxLegend from './components/Jepx/JepxLegend'
import JepxTimeRow from './components/Jepx/JepxTimeRow'
import JepxDayArea from './JepxDayArea.vue'
import JepxTemperatureRow from './JepxTemperatureRow.vue'
import JepxSunlightRow from './JepxSunlightRow.vue'
import JepxWeatherRow from './JepxWeatherRow.vue'
// import JepxHjksRow from './JepxHjksRow.vue'
import JepxSegmentRow from './JepxSegmentRow.vue'
import JepxTimeChart from './components/Jepx/JepxTimeChart'
import JepxSeparatorRow from './JepxSeparatorRow.vue'
import JepxSpot from './JepxSpot.vue'
import ItemsByDay from './ItemsByDay.vue'
import ItemsByDayMixin from './mixins/ItemsByDay.js'

import 'common/logo-imk.svg'

export default {
  name: 'Jepx',
  components: {
    JepxLegend,
    JepxTimeRow,
    JepxDayArea,
    JepxTemperatureRow,
    JepxSunlightRow,
    JepxWeatherRow,
    // JepxHjksRow,
    JepxSegmentRow,
    JepxSeparatorRow,
    JepxTimeChart,
    JepxSpot,
    ItemsByDay,
  },
  mixins: [ItemsByDayMixin],
  /**
   * @typedef {Object.<number, string, Array, Object>} Data
   * @property {Object[]} allItems - JEPX情報
   * @property {number} index - JEPX情報Arrayのうち表示するindex
   * @property {string[]} areaKeys - エリアキーのリスト
   * @property {Object.<Array>} weatherActuals - 実際の天気（日別）
   * @property {Object.<Array>} weatherForecasts - 天気予報（日別 24コマ*10エリア）
   * @property {string} currentAreaKey - 現在表示しているタブのエリアキー
   * @property {number} stickyEndColumnNum - スクロールしないカラム群の末尾（先頭からn列目）
   * @property {number[]} stickyPositions - スクロールしないカラム群の右端のx座標
   * @property {number} activeCellIndexes - マウスオーバーされているセルのインデックス（0〜47）
   */
  data() {
    return {
      allItems: gon.jepx_prices,
      index: size(gon.jepx_prices) - 1,
      areaKeys: gon.jepx_area_keys,
      weatherActuals: gon.weather_actuals || {},
      weatherForecasts: gon.weather_forecasts || {},
      currentAreaKey: 'system',
      stickyEndColumnNum: 5,
      stickyPositions: new Array(this.stickyEndColumnNum),
      activeCellIndexes: undefined,
    }
  },
  computed: {
    /**
     * 現在表示している日付のタイムコード（30分刻みで1〜48）
     * @returns {string[]}
     */
    timeCodes() {
      return this.theItems.time_codes
    },
    /**
     * 現在表示している日付の出力用情報 JEPX価格など
     * @returns {Object.<number, string, Array>}
     */
    currentDayPrices() {
      return this.day_prices_of(this.currentAreaKey)
    },
    /**
     * 天気情報 (weatherActuals, weatherForecasts) のkeyのうち現在表示している日付のもの
     * @returns {string}
     */
    weatherKey() {
      return this.theDay.replace(/\./g, '-')
    },
    /**
     * 表示用にフォーマットされた天気情報（予報）
     * 現在選択されているエリアキーのみ抽出し、 formatWeather() で整形する
     * @returns {*|Object[]}
     */
    formattedWeatherForecastsOfCurrentArea() {
      const filteredWeathers = (this.weatherForecasts[this.weatherKey] || []).filter(
        (item) => item && item.area_key === this.currentAreaKey
      )
      const sortedWeathers = this.sortByKey(filteredWeathers, 'datetime')
      return this.formatWeather(sortedWeathers)
    },
    /**
     * 表示用にフォーマットされた天気情報（実積）
     * 現在選択されているエリアキーのみ抽出する
     * @returns {*|Object[]}
     */
    formattedWeatherActualsOfCurrentArea() {
      const filteredWeathers = (this.weatherActuals[this.weatherKey] || []).filter(
        (item) => item && item.area_key === this.currentAreaKey
      )
      return this.sortByKey(filteredWeathers, 'datetime')
    },
    /**
     * アクティブなセルの位置情報
     * マジックナンバーとして引いている3はborder分
     * @returns {({offsetLeft, number, width: number} | undefined)}
     */
    activeCellPosition() {
      if (this.activeCellIndexes === undefined) return undefined
      const el = document.querySelector(`[data-cell-index="${this.activeCellIndexes[0]}"]`)
      if (!el) return undefined
      const rect = el.getBoundingClientRect()
      return {
        offsetLeft: el.offsetLeft,
        width: rect.right - rect.left - 3,
      }
    },
    /**
     * ハイライト領域のスタイル
     * @returns {{transform?: string, width?: string, opacity: number}}
     */
    highlightStyle() {
      return this.activeCellPosition
        ? {
            transform: `translateX(${
              this.activeCellPosition.offsetLeft - this.stickyPositions[1]
            }px)`,
            width: `${this.activeCellPosition.width}px`,
            opacity: 0.4,
          }
        : {}
    },
    /**
     * スクロールしないカラム群のスタイル
     * @returns {({position: string, left: string} | undefined)[]}
     */
    stickyPositionStyles() {
      return this.stickyPositions.map((item) => {
        return item !== undefined
          ? {
              position: 'sticky',
              left: `${item}px`,
            }
          : undefined
      })
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.requestAnimationFrame(() => {
        this.stickyPositions = this.calcStickyPositions(this.stickyEndColumnNum)
      })
    })
  },
  methods: {
    /**
     * JEPX価格からエリアキーでデータを抽出
     * @param {string} areaKey - エリアキー
     * @returns {Object.<any>}
     */
    day_prices_of(areaKey) {
      return this.theItems[areaKey]
    },
    /**
     * スクロールしないセルの右端の座標を返却
     * @param {number} endColumnNum - スクロールしないカラム群の末尾（先頭からn列目）
     * @returns {number[]}
     */
    calcStickyPositions(endColumnNum) {
      return Array.from(document.querySelectorAll('.jepxTable-cell.t-sticky'))
        .slice(0, endColumnNum)
        .map((target) => {
          return target.getBoundingClientRect().left
        })
    },
    /**
     * タブがクリックされた際、そのタブのエリアキーを保存
     * @param {string} areaKey - エリアキー
     */
    onChangeTab(areaKey) {
      this.currentAreaKey = areaKey
    },
    /**
     * 天気予報は30分刻みではないため複製して30分刻みに変換して返却
     * 天気コードはすべてのコマに存在するものではないため、weather_typeがnullの場合に直前の天気コードを挿入する
     * 何時間刻みであるかは可変と考える。ただし00:00に存在することを前提とする
     * @param {Object[]} data
     * @returns {Object[]}
     */
    formatWeather(data) {
      return data.flatMap((item, index, array) => {
        if (item.weather_type === null) {
          item.weather_type = array[index - 1]?.weather_type ?? null
        }
        return [item, item]
      })
    },
    /**
     * オブジェクトを格納した配列を、指定のキーで昇順ソート
     * @param {Object[]} array
     * @param {string} key
     * @returns {Object[]}
     */
    sortByKey(array, key) {
      return [...array].sort(function (prev, next) {
        return prev[key] > next[key] ? 1 : -1
      })
    },
    /**
     * マウスオーバーされているセルのインデックスを更新する
     * @param {(number | string)[] | undefined} indexes - インデックス
     */
    changeActiveCellIndexes(indexes) {
      this.activeCellIndexes = indexes
    },
  },
}
</script>

<style lang="scss" scoped>
@use 'css/foundation/_vars.scss';
@import 'css/vue_modules/_jepx_table.scss';

.jepxSection {
  max-width: 100%;
  padding: 12px 0;
  margin-top: -2px;
  background: vars.$color-bg-1;
  border-top: 2px solid vars.$color-assist-1;
}

.jepxSection-tableContainer {
  position: relative;
  max-width: 100%;
  padding: 0 0 8px;
  margin-top: 8px;
  overflow-x: scroll;
}

.jepxSection-help {
  padding: 0 16px;
  margin-top: 6px;
  text-align: right;

  a {
    color: vars.$color-text;
  }
}

.jepxTable-chartWrapper {
  position: relative;
  background: #000;
}

.jepxTable-chartWrapper-inner {
  position: relative;
  z-index: 2;
}

.jepxTable-highlight {
  position: absolute;
  top: -24px;
  left: 0;
  z-index: 1;
  height: calc(100% + 24px);
  pointer-events: none;
  background: #fff;
  opacity: 0;
  transition: opacity 0.1s ease-out;
  transform: translateX(0);
}
</style>
