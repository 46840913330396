/**
 * 天気の表示用情報
 * https://github.com/enechange/enein-markets/blob/master/app/models/weather_actual.rb
 * @returns {{cloudy_nighttime: {path: *, text: string, class: string}, rainy_or_snowy: {path: *, text: string, class: string}, rainy: {path: *, text: string, class: string}, sunny_nighttime: {path: *, text: string, class: string}, missing: {path: *, text: string, class: string}, clear_nighttime: {path: *, text: string, class: string}, cloudy_daytime: {path: *, text: string, class: string}, sunny_daytime: {path: *, text: string, class: string}, clear_daytime: {path: *, text: string, class: string}, snowy: {path: *, text: string, class: string}}}
 */
export default function weatherIconPaths() {
  return {
    clear_daytime: {
      text: '快晴',
      path: this.getImagePath('common/icon-weather-clear-daytime.svg'),
      class: 'c-clear-daytime',
    },
    clear_nighttime: {
      text: '快晴',
      path: this.getImagePath('common/icon-weather-clear-nighttime.svg'),
      class: 'c-clear-nighttime',
    },
    sunny_daytime: {
      text: '晴れ',
      path: this.getImagePath('common/icon-weather-sunny-daytime.svg'),
      class: 'c-sunny-daytime',
    },
    sunny_nighttime: {
      text: '晴れ',
      path: this.getImagePath('common/icon-weather-sunny-nighttime.svg'),
      class: 'c-sunny-nighttime',
    },
    cloudy_daytime: {
      text: '曇り',
      path: this.getImagePath('common/icon-weather-cloudy-daytime.svg'),
      class: 'c-cloudy-daytime',
    },
    cloudy_nighttime: {
      text: '曇り',
      path: this.getImagePath('common/icon-weather-cloudy-nighttime.svg'),
      class: 'c-cloudy-nighttime',
    },
    rainy: {
      text: '雨',
      path: this.getImagePath('common/icon-weather-rainy.svg'),
      class: 'c-rainy',
    },
    rainy_or_snowy: {
      text: '雨または雪',
      path: this.getImagePath('common/icon-weather-rainy-or-snowy.svg'),
      class: 'c-rainy-or-snowy',
    },
    snowy: {
      text: '雪',
      path: this.getImagePath('common/icon-weather-snowy.svg'),
      class: 'c-snowy',
    },
    missing: {
      text: '',
      path: this.getImagePath('common/icon-missing.svg'),
      class: 'c-missing',
    },
  }
}
