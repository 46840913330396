export default {
  /**
   * @property {(number | string)[]} activeCellIndexes - アクティブなセルのインデックス
   */
  props: {
    activeCellIndexes: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  methods: {
    /**
     * マウスオーバーされているセルのインデックスを親に伝達する
     * @param {(number | string)[]} indexes - インデックス
     */
    onMouseOver(indexes) {
      this.$emit('change-active-cell-indexes', indexes)
    },
    /**
     * マウスアウトしたことでどのセルもアクティブでなくなったことを親に伝達する
     */
    onMouseOut() {
      this.$emit('change-active-cell-indexes', undefined)
    },
    /**
     * セルがアクティブかどうか判定する
     * @param {(number | string)[]} indexes - インデックス
     * @returns {boolean}
     */
    checkIsActive(indexes) {
      return this.activeCellIndexes?.some((activeCellIndex) => indexes.includes(activeCellIndex))
    },
  },
}
