/**
 * タイムコードから hh, mm を生成
 * @returns {{hour: string, timeCode: string, minute: string}[]}
 */
export default function formatTimeCodes(timeCodes) {
  return timeCodes.map((timeCode) => {
    const inMinute = (parseInt(timeCode, 10) - 1) * 30
    const minute = inMinute % 60
    const hour = (inMinute - minute) / 60
    return {
      timeCode,
      hour: `${hour}`.padStart(2, '0'),
      minute: `${minute}`.padStart(2, '0'),
    }
  })
}
