<template>
  <div class="JepxTimeChart">
    <div class="JepxTimeChart-inner">
      <div class="JepxTimeChart-legendY">
        <p class="JepxTimeChart-legendY-unit">(円/kWh)</p>
        <p v-show="weatherData.isVisible" class="JepxTimeChart-legendY-unit">(℃)</p>
      </div>
      <div class="JepxTimeChart-chartRow">
        <div class="JepxTimeChart-legend">
          <div class="JepxTimeChart-colorWrapper">
            <div class="JepxTimeChart-colorWrapper-group">
              <p class="JepxTimeChart-colorWrapper-title">電力エリア</p>
              <ul class="JepxTimeChart-colorList">
                <li class="JepxTimeChart-colorItem">
                  <span
                    class="JepxTimeChart-colorItem-sample"
                    :style="{ 'border-color': currentAreaColor.point }"
                  ></span
                  >{{ currentArea.name }}
                </li>
              </ul>
            </div>

            <div v-if="weatherData.isVisible" class="JepxTimeChart-colorWrapper-group">
              <p class="JepxTimeChart-colorWrapper-title">気温・天気</p>
              <ul class="JepxTimeChart-colorList">
                <li class="JepxTimeChart-colorItem">
                  <span class="JepxTimeChart-colorItem-weather"></span>{{ weatherData.type }}
                </li>
              </ul>
            </div>
          </div>

          <div class="JepxTimeChart-yAxesLegendBar">
            <div
              v-for="legend in yAxesColorLegends"
              :key="legend.level"
              class="JepxTimeChart-yAxesLegendItem"
              :class="`t-l${legend.level}`"
              :style="{ height: `${legend.length}px` }"
            ></div>
          </div>
        </div>
        <div class="JepxTimeChart-chartWrapper" :style="{ paddingLeft: offsetLeft }">
          <canvas
            ref="JepxTimeChart"
            width="2118"
            height="204"
            class="JepxTimeChart-canvas"
          ></canvas>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js'

import JepxTableRowMixin from '../../../mixins/JepxTableRow'
import WeatherIconMixin from '../../../mixins/WeatherIcon'

import formatTimeCodes from '../../../functions/formatTimeCodes'
import withDelimiter from '../../../functions/withDelimiter'
import hexToRgb from '../../../functions/hexToRgb'
import getStepSize from '../../../functions/chart/getStepSize'
import getMaxLimit from '../../../functions/chart/getMaxLimit'
import createMinmaxDatasets from '../../../functions/chart/createMinmaxDatasets'

import { areasWithSystem } from '../../../data/areas'
import colorThresholds from '../../../data/colorThresholds'
import getWeatherIcon from '../../../functions/getWeatherIcon'
import loadImage from '../../../functions/loadImage'

export default {
  name: 'JepxTimeChart',
  mixins: [JepxTableRowMixin, WeatherIconMixin],
  props: {
    theItems: {
      type: Object,
      required: true,
    },
    weatherForecasts: {
      type: Array,
      required: false,
      default: undefined,
    },
    weatherActuals: {
      type: Array,
      required: false,
      default: undefined,
    },
    currentAreaKey: {
      type: String,
      required: true,
    },
    timeCodes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chartObj: null,
      images: {},
      isPreloaded: false,
    }
  },
  computed: {
    ctx() {
      return this.$refs.JepxTimeChart
    },
    areas() {
      return areasWithSystem()
    },
    areaIds() {
      return this.areas.map((area) => area.id)
    },
    currentArea() {
      return this.areas.find((area) => area.id === this.currentAreaKey)
    },
    currentItems() {
      return this.theItems[this.currentAreaKey]
    },
    prices() {
      return this.currentItems.prices
    },
    maxPriceOfAllAreas() {
      return Math.max(
        ...Object.entries(this.theItems)
          .filter(([key, _]) => this.areaIds.includes(key))
          .map(([_, value]) => Math.max(...value.prices))
      )
    },
    maxPrice() {
      return this.currentItems.max
    },
    minPrice() {
      return this.currentItems.min
    },
    colors() {
      return Object.fromEntries(this.areas.map((area) => [area.id, area.chart.color]))
    },
    currentAreaColor() {
      const hex = this.colors[this.currentAreaKey]
      const rgb = hexToRgb(hex).toString()
      return {
        point: hex,
        line: `rgba(${rgb}, 0.5)`,
      }
    },
    stepSize() {
      return getStepSize(this.maxPriceOfAllAreas)
    },
    maxLimit() {
      return getMaxLimit(this.maxPriceOfAllAreas, this.stepSize)
    },
    offsetLeft() {
      return `${(3 - this.maxLimit.toString().length) * 0.5}rem`
    },
    labels() {
      return formatTimeCodes(this.timeCodes).map((item) => `${item.hour}:${item.minute}`)
    },
    weatherData() {
      if (this.weatherForecasts?.length) {
        return {
          isVisible: true,
          type: '予報',
          temperature: this.weatherForecasts.map((weather) => weather.temperature),
          weatherType: this.weatherForecasts.map((weather) => weather.weather_type),
          color: {
            point: '#fff',
            line: 'rgba(255, 255, 255, 0.5)',
          },
        }
      } else if (this.weatherActuals?.length) {
        return {
          isVisible: true,
          name: '実績',
          temperature: this.weatherActuals.map((weather) => weather.temperature),
          weatherType: this.weatherActuals.map((weather) => weather.weather_type),
          color: {
            point: '#fff',
            line: 'rgba(255, 255, 255, 0.5)',
          },
        }
      } else {
        return {
          isVisible: false,
        }
      }
    },
    options() {
      return {
        responsive: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              id: 'x-period',
              scaleLabel: {
                display: false,
              },
              ticks: {
                fontFamily: '"Oswald", "M PLUS 1p", sans-serif',
                fontColor: '#fff',
                fontSize: 11,
                fontStyle: 'normal',
                padding: 8,
              },
              gridLines: {
                zeroLineColor: '#fff',
                tickMarkLength: 0,
                drawBorder: true,
              },
            },
          ],
          yAxes: this.yAxes,
        },
        elements: {
          line: {
            tension: 0,
          },
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 28,
            bottom: 0,
          },
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          axis: 'x',
          filter: (item) => {
            return this.datasetIndexesIsEnabledTooltip.includes(item.datasetIndex)
          },
        },
        hover: {
          mode: 'index',
          intersect: false,
          axis: 'x',
          onHover: (_e, item) => {
            if (item.length) {
              const index = item[0]._index
              if (!this.activeCellIndexes?.includes(index)) this.onMouseOver([index])
            } else {
              if (this.activeCellIndexes) this.onMouseOut()
            }
          },
        },
      }
    },
    datasetIndexesIsEnabledTooltip() {
      return [0, 1]
    },
    yAxesTemplate() {
      return {
        type: 'linear',
        scaleLabel: {
          display: false,
        },
        ticks: {
          beginAtZero: true,
          fontColor: '#fff',
          fontSize: 10,
          fontStyle: 'bold',
          max: this.maxLimit,
          stepSize: this.stepSize,
          callback: (value) => {
            return value ? withDelimiter(value) : ''
          },
        },
        gridLines: {
          drawBorder: true,
          drawOnChartArea: false,
        },
      }
    },
    yAxes() {
      return [
        {
          ...this.yAxesTemplate,
          id: 'y-price',
          position: 'left',
          gridLines: {
            display: true,
            color: '#333',
            borderDash: [1, 2],
          },
        },
        {
          ...this.yAxesTemplate,
          id: 'y-temperature',
          position: 'right',
          ticks: {
            beginAtZero: false,
            fontColor: this.weatherData.isVisible ? '#fff' : '#000',
            fontSize: 10,
            fontStyle: 'bold',
            callback: (value) => {
              return `${value?.toFixed(1)}` ?? ''
            },
          },
        },
      ]
    },
    lineDatasetsTemplate() {
      return {
        type: 'line',
        position: 'left',
        borderWidth: 1,
        hoverBorderWidth: 2,
        pointBorderWidth: 3,
        pointHoverBorderWidth: 3,
        pointRadius: 3.5,
        pointHoverRadius: 4.5,
        fill: false,
        lineTension: 0,
        spanGaps: true,
      }
    },
    priceDataset() {
      return {
        ...this.lineDatasetsTemplate,
        label: this.currentArea.name,
        data: this.prices,
        borderColor: this.currentAreaColor.line,
        pointBorderColor: this.currentAreaColor.point,
        borderDash: [2, 3],
        position: 'left',
        pointBackgroundColor: '#000',
        xAxisID: 'x-period',
        yAxisID: 'y-price',
      }
    },
    temperaturePointStyle() {
      return this.weatherData?.weatherType
        ? this.weatherData?.weatherType.map((item, index) => {
            const path = getWeatherIcon({
              weatherName: item ?? 'missing',
              timeCode: index,
              weatherIconPaths: this.weatherIconPaths,
            }).path
            return this.images[path]
          })
        : undefined
    },
    temperatureDataset() {
      return {
        ...this.lineDatasetsTemplate,
        label: `気温(${this.weatherData?.type})`,
        data: this.weatherData?.temperature,
        borderColor: this.weatherData?.color?.line,
        pointBorderColor: this.weatherData?.color?.point,
        pointStyle: this.temperaturePointStyle,
        position: 'left',
        pointBackgroundColor: this.weatherData?.color?.point,
        xAxisID: 'x-period',
        yAxisID: 'y-temperature',
      }
    },
    minmaxDatasets() {
      return createMinmaxDatasets({
        maxValue: this.maxPrice,
        minValue: this.minPrice,
        xAxisID: 'x-period',
        yAxisID: 'y-price',
      })
    },
    datasets() {
      return [
        this.priceDataset,
        this.temperatureDataset,
        this.minmaxDatasets.maxLine,
        this.minmaxDatasets.minLine,
        this.minmaxDatasets.dummyBar,
      ]
    },
    params() {
      return {
        type: 'bar',
        data: {
          labels: this.labels,
          datasets: this.datasets,
        },
        options: this.options,
      }
    },
    updateOption() {
      return { duration: 0, lazy: true, easing: 'linear' }
    },
    colorThresholds() {
      return colorThresholds()
    },
    yAxesLength() {
      return 148
    },
    yAxesColorLegends() {
      const ratio = this.yAxesLength / this.maxLimit
      return this.colorThresholds.map((item, index) => {
        const min = item.min ?? 0
        const max = index === 0 ? this.maxLimit : this.colorThresholds[index - 1].min ?? 0
        return {
          ...item,
          length: (max - min) * ratio,
        }
      })
    },
  },
  watch: {
    theItems() {
      this.updateChart()
    },
    currentAreaKey() {
      this.updateChart()
    },
  },
  created() {
    this.preload()
  },
  mounted() {
    const unwatchPreloading = this.$watch(
      'isPreloaded',
      (val) => {
        if (val) {
          this.chartObj = new Chart(this.ctx, this.params)
          if (unwatchPreloading) unwatchPreloading()
        }
      },
      { immediate: true }
    )
  },
  methods: {
    preload() {
      const weatherIconPathsArray = Object.values(this.weatherIconPaths)
      const countOfImages = weatherIconPathsArray.length
      const loadedImages = []
      weatherIconPathsArray.forEach(async (value) => {
        this.images[value.path] = await loadImage(value.path).catch((_e) => {})
        loadedImages.push(value.path)
        if (loadedImages.length === countOfImages) this.isPreloaded = true
      })
    },
    updateChart() {
      this.chartObj.data.datasets = this.datasets
      this.chartObj.data.labels = this.labels
      this.chartObj.options.scales.yAxes = this.yAxes
      this.$nextTick(() => {
        this.chartObj.update(this.updateOption)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@use "../../../css/foundation/vars";
@import '../../../css/vue_modules/content_heading';

//.JepxTimeChart {
//}

.JepxTimeChart-inner {
  margin-top: 24px;
}

.JepxTimeChart-chartRow {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  padding: 0 0 8px 78px;
}

.JepxTimeChart-chartWrapper {
  position: relative;
  flex: 1 1 auto;
  color: vars.$color-text;
}

.JepxTimeChart-legend {
  display: flex;
  flex: 0 0 8.2rem;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: stretch;
  padding-top: 30px;
}

.JepxTimeChart-colorWrapper {
  flex: 1 1 auto;
}

.JepxTimeChart-colorWrapper-group {
  & + & {
    margin-top: 2.4rem;
  }
}

.JepxTimeChart-colorWrapper-title {
  font-size: 1rem;
  font-weight: bold;
}

.JepxTimeChart-colorList {
  display: block;
  margin-top: 0.8rem;
}

.JepxTimeChart-colorItem-sample {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 10px;
  height: 10px;
  margin: auto;
  background: #000;
  border-style: solid;
  border-width: 3px;
  border-radius: 50%;
}

.JepxTimeChart-colorItem-weather {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 12px;
  height: 12px;
  background: url(~common/icon-weather-clear-daytime.svg);
  background-size: 100% auto;
}

.JepxTimeChart-colorItem {
  position: relative;
  display: block;
  min-width: 3.5em;
  padding-left: 14px;
  font-size: 1rem;

  & + & {
    margin-top: 0.4rem;
  }
}

.JepxTimeChart-canvas {
  margin-top: 2px;
  margin-left: 4px;
}

.JepxTimeChart-yAxesLegendBar {
  display: flex;
  flex: 0 0 8px;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  width: 8px;
  height: 148px;
  background: map-get(vars.$colors-chart, 'l0');
}

.JepxTimeChart-yAxesLegendItem {
  @each $key, $val in vars.$colors-chart {
    &.t-#{$key} {
      background: $val;
    }
  }
}

.JepxTimeChart-legendY {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  padding: 8px 92px 0 156px;
  pointer-events: none;
}

.JepxTimeChart-legendY-unit {
  font-size: 1rem;
  font-weight: vars.$font-weight-medium;
}
</style>
