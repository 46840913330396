<template>
  <tr class="jepxTable-row">
    <th class="jepxTable-cell t-padding t-sticky" :style="stickyPositions[0]"></th>
    <th class="jepxTable-cell t-heading t-noBG t-sticky" :style="stickyPositions[1]">
      <span class="jepxTable-areaName">{{ currentAreaName }}</span>
    </th>
    <th
      class="jepxTable-cell t-overview t-sticky"
      :style="stickyPositions[2]"
      @mouseover="onMouseOver(['max'])"
      @mouseout="onMouseOut()"
    >
      <span class="jepxTable-cellInner" :class="{ 'is-active': checkIsActive(['max']) }">Max</span>
    </th>
    <th
      class="jepxTable-cell t-overview t-sticky"
      :style="stickyPositions[3]"
      @mouseover="onMouseOver(['min'])"
      @mouseout="onMouseOut()"
    >
      <span class="jepxTable-cellInner" :class="{ 'is-active': checkIsActive(['min']) }">Min</span>
    </th>
    <th
      class="jepxTable-cell t-overview t-sticky t-sectionEnd"
      :style="stickyPositions[4]"
      @mouseover="onMouseOver(['average'])"
      @mouseout="onMouseOut()"
    >
      <span class="jepxTable-cellInner" :class="{ 'is-active': checkIsActive(['average']) }"
        >Avg.</span
      >
    </th>
    <th
      v-for="(time, index) in formattedTimes"
      :key="time.timeCode"
      class="jepxTable-cell t-time"
      :class="{ 't-sectionEnd': checkIsSectionEnd(time.timeCode) }"
      :data-cell-index="index"
      @mouseover="onMouseOver([index])"
      @mouseout="onMouseOut()"
    >
      <span class="jepxTable-cellInner" :class="{ 'is-active': checkIsActive([index]) }">
        {{ time.hour }}<span class="jepxTable-minute">:{{ time.minute }}</span>
      </span>
    </th>
    <th class="jepxTable-cell t-padding"></th>
  </tr>
</template>

<script>
import JepxTableRowMixin from '../../../mixins/JepxTableRow'

import formatTimeCodes from '../../../functions/formatTimeCodes'

export default {
  name: 'JepxTimeRow',
  mixins: [JepxTableRowMixin],
  /**
   * @property {Object.<number, string, Array>} dayPrices - 日付の出力用情報 JEPX価格など
   * @property {string[]} timeCodes - タイムコードのリスト
   * @property {Object[]} [stickyPositions] - スクロールしないカラム群の右端のx座標（CSSとして解釈できる文字列）
   */
  props: {
    dayPrices: {
      type: Object,
      required: true,
    },
    timeCodes: {
      type: Array,
      required: true,
    },
    stickyPositions: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    /**
     * 表示するエリア名 システムの場合は全国に差し替える
     * @returns {string}
     */
    currentAreaName() {
      return this.dayPrices.name === 'システム' ? '全国' : this.dayPrices.name
    },
    /**
     * タイムコードから hh, mm を生成
     * @returns {{hour: string, timeCode: string, minute: string}[]}
     */
    formattedTimes() {
      return formatTimeCodes(this.timeCodes)
    },
  },
  methods: {
    /**
     * 正午の場合に true を返す。午前と午後の間に区切り線を入れるために使用
     * @param {string} timeCode
     * @returns {boolean}
     */
    checkIsSectionEnd(timeCode) {
      return timeCode === '24'
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'css/vue_modules/_jepx_table.scss';
</style>
