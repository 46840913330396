<template>
  <tr class="jepxTable-row">
    <td class="jepxTable-cell t-padding t-sticky" :style="stickyPositions[0]"></td>
    <td class="jepxTable-cell t-heading t-sticky" :style="stickyPositions[1]">
      <span class="jepxTable-cellInner" :class="titleClassName">気温({{ titleSuffix }})</span>
    </td>
    <td
      class="jepxTable-cell t-sticky t-smallerText"
      :style="stickyPositions[2]"
      @mouseover="onMouseOver(['max'])"
      @mouseout="onMouseOut()"
    >
      <span class="jepxTable-cellInner" :class="{ 'is-active': checkIsActive(['max']) }"
        >{{ max | fixed }}<span class="c-unit">℃</span></span
      >
    </td>
    <td
      class="jepxTable-cell t-sticky t-smallerText"
      :style="stickyPositions[3]"
      @mouseover="onMouseOver(['min'])"
      @mouseout="onMouseOut()"
    >
      <span class="jepxTable-cellInner" :class="{ 'is-active': checkIsActive(['min']) }"
        >{{ min | fixed }}<span class="c-unit">℃</span></span
      >
    </td>
    <td
      class="jepxTable-cell t-sticky t-smallerText t-sectionEnd"
      :style="stickyPositions[4]"
      @mouseover="onMouseOver(['average'])"
      @mouseout="onMouseOut()"
    >
      <span class="jepxTable-cellInner" :class="{ 'is-active': checkIsActive(['average']) }"
        >{{ average | fixed }}<span class="c-unit">℃</span></span
      >
    </td>

    <td
      v-for="(item, index) in items"
      :key="index"
      class="jepxTable-cell t-smallerText"
      @mouseover="onMouseOver([index])"
      @mouseout="onMouseOut()"
    >
      <span class="jepxTable-cellInner" :class="{ 'is-active': checkIsActive([index]) }"
        >{{ item | fixed }}<span class="c-unit">℃</span></span
      >
    </td>

    <td class="jepxTable-cell t-padding"></td>
  </tr>
</template>

<script>
import JepxTableRowMixin from './mixins/JepxTableRow'

import { mean } from './functions/calculator'

export default {
  name: 'JepxTemperatureRow',
  filters: {
    fixed(num) {
      return typeof num === 'number' && !Number.isNaN(num) ? num.toFixed(1) : '-'
    },
  },
  mixins: [JepxTableRowMixin],
  /**
   * @typedef {Object.<Array>} Props
   * @property {Object[]} weathers - 天気情報のリスト
   * @property {Object[]} [stickyPositions] - スクロールしないカラム群の右端のx座標（CSSとして解釈できる文字列）
   * @property {boolean} isForecast - 予報であるか
   */
  props: {
    weathers: {
      type: Array,
      required: true,
    },
    stickyPositions: {
      type: Array,
      required: false,
      default: () => [],
    },
    isForecast: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    /**
     * タイトル表示セルの末尾テキスト
     * @returns {string}
     */
    titleSuffix() {
      return this.isForecast ? '予報' : '実積'
    },
    /**
     * タイトル表示セルの追加class
     * @returns {string}
     */
    titleClassName() {
      return this.isForecast ? 't-heading' : 't-heading-light'
    },
    /**
     * 天気情報から気温のみ抽出した配列
     * 気温が異常値 (-99.9) の場合があるため、それを欠損値 (null) として処理
     * 天気情報が存在しない場合はすべてnull埋め
     * @returns {Array.<number|null>}
     */
    items() {
      return this.weathers[0]
        ? this.weathers.map((item) =>
            item.temperature !== '-99.9' ? parseFloat(item.temperature) : null
          )
        : new Array(48).fill(null)
    },
    /**
     * 気温情報の配列から有効な値のみ抽出したもの
     * @returns {Array.<number>}
     */
    validItems() {
      return this.items.filter((item) => typeof item === 'number')
    },
    /**
     * 最高気温（気温の最大値を抽出）
     * @returns {number|null}
     */
    max() {
      return this.weathers[0] ? Math.max(...this.validItems) : null
    },
    /**
     * 最低気温（気温の最小値を抽出）
     * @returns {number|null}
     */
    min() {
      return this.weathers[0] ? Math.min(...this.validItems) : null
    },
    /**
     * 日平均気温（気温の平均値を算出）
     * @returns {number}
     */
    average() {
      return mean(this.validItems)
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'css/vue_modules/_jepx_table.scss';

.c-unit {
  font-weight: 600;
}
</style>
