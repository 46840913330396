<template>
  <tr class="jepxTable-row">
    <td class="jepxTable-cell t-padding t-sticky" :style="stickyPositions[0]"></td>
    <td class="jepxTable-cell t-heading t-sticky" :style="stickyPositions[1]">
      <span class="jepxTable-cellInner t-heading-light">日照(実積)</span>
    </td>
    <td
      class="jepxTable-cell t-spanned t-sticky t-smallerText t-sectionEnd"
      colspan="3"
      :style="stickyPositions[2]"
      @mouseover="onMouseOver(['max', 'min', 'average'])"
      @mouseout="onMouseOut()"
    >
      <span
        class="jepxTable-cellInner"
        :class="{ 'is-active': checkIsActive(['max', 'min', 'average']) }"
        >合計{{ addUnit(total) || '-' }} / {{ sunshineRate }}%</span
      >
    </td>

    <td
      v-for="(item, index) in itemsWithUnit"
      :key="item.id"
      class="jepxTable-cell t-smallerText"
      @mouseover="onMouseOver([index])"
      @mouseout="onMouseOut()"
    >
      <span class="jepxTable-cellInner" :class="{ 'is-active': checkIsActive([index]) }">
        <template v-if="item.value">
          {{ item.value }}
        </template>
        <template v-else>
          <img
            :src="missingIcon.path"
            :alt="missingIcon.text"
            class="c-icon"
            :class="missingIcon.class"
          />
        </template>
      </span>
    </td>

    <td class="jepxTable-cell t-padding"></td>
  </tr>
</template>

<script>
import { sum } from './functions/calculator'

import { areasWithOkiden } from './data/areas'

import JepxTableRowMixin from './mixins/JepxTableRow'
import WeatherIconMixin from './mixins/WeatherIcon'

import 'common/icon-missing.svg'

// import { getTimes } from 'suncalc'
const { getTimes } = require('suncalc/suncalc.js')

export default {
  name: 'JepxSunlightRow',
  mixins: [JepxTableRowMixin, WeatherIconMixin],
  /**
   * @typedef {Object.<string, boolean, Array>} Props
   * @property {Object[]} weathers - 天気情報リスト
   * @property {Object[]} [stickyPositions] - スクロールしないカラム群の右端のx座標（CSSとして解釈できる文字列）
   * @property {string[]} weatherKey - 天気情報のキー（日付）
   * @property {string} currentAreaKey - 表示されているエリアキー
   */
  props: {
    weathers: {
      type: Array,
      required: true,
    },
    stickyPositions: {
      type: Array,
      required: false,
      default: () => [],
    },
    weatherKey: {
      type: String,
      required: true,
    },
    currentAreaKey: {
      type: String,
      required: true,
    },
  },
  computed: {
    /**
     * データが存在しない場合のアイコン
     * @returns {Object}
     */
    missingIcon() {
      return this.weatherIconPaths.missing
    },
    /**
     * 日照時間（00:00〜23:30の48コマ）
     * 単位が分なので時に直す
     * @returns {Array.<?number>}
     */
    items() {
      return this.isVisibleValue
        ? this.weathers.map((item) => item.sunshine / 60)
        : new Array(48).fill(null)
    },
    /**
     * 日照時間に単位(h)を付加したもの
     * @returns {Array.<?Object>}
     */
    itemsWithUnit() {
      return this.items.map((item, index) => ({
        id: `${this.currentAreaKey}-${this.weatherKey}-${index}`,
        value: this.addUnit(item),
      }))
    },
    /**
     * 値を表示可能であるか
     * バッチエラーか何かで値を取得できなかった場合のランタイムエラー避け
     * @returns {boolean}
     */
    isVisibleValue() {
      return this.weathers.length > 0
    },
    /**
     * 日照時間の合計値
     * @returns {string|number}
     */
    total() {
      return this.isVisibleValue ? sum(this.items) : undefined
    },
    /**
     * 日照率
     * 各エリアの対象となる都市にある地方気象台の経緯度と日付で補正している
     * フロントエンドで計算するものなのかという指摘も
     * @returns {string|number}
     */
    sunshineRate() {
      if (!this.isVisibleValue || this.total === '-') return '-'
      const sunlightData = getTimes(
        new Date(`${this.weatherKey}T00:00:00+09:00`),
        this.currentPosition[0],
        this.currentPosition[1]
      )
      const sunlightTimes = sunlightData.sunset.getTime() - sunlightData.sunrise.getTime()
      const sunlightHours = sunlightTimes / (1000 * 60 * 60)
      return Math.min(Math.floor((this.total / sunlightHours) * 100), 100)
    },
    /**
     * 表示されている都市の [緯度, 経度]
     * @returns {number[]}
     */
    currentPosition() {
      return this.position[this.currentAreaKey]
    },
    /**
     * エリアごとの各地方気象台の [緯度, 経度]
     * @returns {{kyuden: number[], rikuden: number[], kepco: number[], hepco: number[], energia: number[], tepco: number[], "tohoku-epco": number[], chuden: number[], okiden: number[], yonden: number[]}}
     */
    position() {
      const obj = {}
      areasWithOkiden().forEach(({ id, positionForSunlight }) => {
        obj[id] = positionForSunlight
      })
      return obj
    },
  },
  methods: {
    /**
     * 小数第一位より下を四捨五入して単位(h)を付加 値がなければ '-' を返却
     * TODO: 切り上げか切り捨ての可能性がある。一般的な表記を要確認だが、明らかでないまま世に出てしまった
     * @param {number?} num
     * @returns {?string}
     */
    addUnit(num) {
      return typeof num === 'number' ? `${Math.round(num * 10) / 10}h` : null
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'css/vue_modules/_jepx_table.scss';
@import 'css/vue_modules/_weather_icons.scss';
</style>
