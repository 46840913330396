<template>
  <tr class="jepxTable-row">
    <td class="jepxTable-cell t-padding t-sticky" :style="stickyPositions[0]"></td>
    <td class="jepxTable-cell t-heading t-sticky" :style="stickyPositions[1]">
      <span class="jepxTable-cellInner" :class="titleClassName">天気({{ titleSuffix }})</span>
    </td>
    <td
      class="jepxTable-cell t-spanned t-sticky t-smallerText t-sectionEnd"
      colspan="3"
      :style="stickyPositions[2]"
      @mouseover="onMouseOver(['max', 'min', 'average'])"
      @mouseout="onMouseOut()"
    >
      <span
        class="jepxTable-cellInner"
        :class="{ 'is-active': checkIsActive(['max', 'min', 'average']) }"
        >{{ dailyWeather }}</span
      >
    </td>

    <td
      v-for="(icon, index) in weatherIcons"
      :key="index"
      class="jepxTable-cell"
      @mouseover="onMouseOver([index])"
      @mouseout="onMouseOut()"
    >
      <span class="jepxTable-cellInner" :class="{ 'is-active': checkIsActive([index]) }">
        <img
          v-if="icon.path"
          :src="icon.path"
          :alt="icon.text"
          class="c-icon"
          :class="icon.class"
        />
        <template v-else>-</template>
      </span>
    </td>

    <td class="jepxTable-cell t-padding"></td>
  </tr>
</template>

<script>
import JepxTableRowMixin from './mixins/JepxTableRow'
import WeatherIconMixin from './mixins/WeatherIcon'

import getWeatherIcon from './functions/getWeatherIcon'

import 'common/icon-weather-sunny-daytime.svg'
import 'common/icon-weather-sunny-nighttime.svg'
import 'common/icon-weather-cloudy-daytime.svg'
import 'common/icon-weather-cloudy-nighttime.svg'
import 'common/icon-weather-rainy.svg'
import 'common/icon-weather-rainy-or-snowy.svg'
import 'common/icon-weather-snowy.svg'
import 'common/icon-missing.svg'

export default {
  name: 'JepxWeatherRow',
  mixins: [JepxTableRowMixin, WeatherIconMixin],
  /**
   * @typedef {Object.<Array>} Props
   * @property {Object[]} weathers - 天気情報のリスト
   * @property {Object[]} [stickyPositions] - スクロールしないカラム群の右端のx座標（CSSとして解釈できる文字列）
   * @property {boolean} isForecast - 予報であるか
   */
  props: {
    weathers: {
      type: Array,
      required: true,
    },
    stickyPositions: {
      type: Array,
      required: false,
      default: () => [],
    },
    isForecast: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    /**
     * タイトル表示セルの末尾テキスト
     * @returns {string}
     */
    titleSuffix() {
      return this.isForecast ? '予報' : '実積'
    },
    /**
     * タイトル表示セルの追加class
     * @returns {string}
     */
    titleClassName() {
      return this.isForecast ? 't-heading' : 't-heading-light'
    },
    /**
     * TODO: 日別の天気データが追加され次第、それを参照する。それまでは空欄にする
     * @returns {string}
     */
    dailyWeather() {
      return ''
    },
    /**
     * 天気キーのみの配列を生成（00:00〜23:30の48コマ）
     * 天気情報が存在しない場合は0で埋める
     * @returns {?string[]}
     */
    weatherTypes() {
      return this.weathers[0]
        ? this.weathers.map((item) => item.weather_type || null)
        : new Array(48).fill(null)
    },
    /**
     * 天気キーからweatherIconPathsのオブジェクトを選択し48コマの配列で返却
     */
    weatherIcons() {
      return this.weatherTypes.map((item, index) => {
        return getWeatherIcon({
          weatherName: item ?? 'missing',
          timeCode: index,
          weatherIconPaths: this.weatherIconPaths,
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'css/vue_modules/_jepx_table.scss';
@import 'css/vue_modules/_weather_icons.scss';
</style>
