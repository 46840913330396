/**
 * 数値を固定小数点表記の文字列に変換
 * TODO: いくつかのコンポーネントで使われている fixed フィルターをこの関数で共通化する
 * @param {number} num 変換する数値
 * @param {number} [fractionDigits] 小数点以下の桁数
 * @param {string} [fallbackText] 数値を変換できない場合の代替テキスト
 * @returns {string}
 */
export default function numToFixedPointStr(num, fractionDigits = 2, fallbackText = '-') {
  return typeof num === 'number' && !Number.isNaN(num) ? num.toFixed(fractionDigits) : fallbackText
}
