export function sum(array) {
  return array.reduce((previous, current) => {
    return previous + current
  })
}

export function mean(array) {
  return array[0] ? sum(array) / array.length : NaN
}

/**
 * 数列から標準偏差を求める
 * https://qiita.com/FumioNonaka/items/fee07b53fd277b218c97
 * @param array
 * @returns {number}
 */
export function sd(array) {
  const average = array.reduce((previous, current) => previous + current) / array.length
  return Math.sqrt(
    array.reduce((sumOfSquare, current) => sumOfSquare + (current - average) ** 2) / array.length
  )
}
